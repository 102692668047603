var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('div',[_c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('h3',{staticClass:"text-primary pr-2"},[_c('strong',[_vm._v("Smart Start")])]),_c('vuexy-logo',{attrs:{"width":"32","height":"32"}})],1),_c('p',{staticClass:"text-right",staticStyle:{"width":"300px"}})]),_c('div',[_c('b-form-group',{staticClass:"align-items-center",attrs:{"label":"فاتورة رقم","label-cols":"4","label-size":"sm","label-for":"invoceNumber"}},[_c('b-form-input',{staticClass:"ml-auto",staticStyle:{"width":"120px"},attrs:{"id":"invoceNumber","type":"text","readonly":"","size":"sm"},model:{value:(_vm.invoiceDto.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.invoiceDto, "invoiceNumber", $$v)},expression:"invoiceDto.invoiceNumber"}})],1),_c('b-form-group',{staticClass:"align-items-center",attrs:{"label":"تاريخ الفاتورة","label-cols":"4","label-size":"sm","label-for":"invoceDate"}},[_c('b-form-input',{staticClass:"ml-auto",staticStyle:{"width":"120px"},attrs:{"id":"invoceDate","type":"text","readonly":"","size":"sm","value":new Date(_vm.invoiceDto.date)
                                        .toISOString()
                                        .substr(0, 10)}})],1)],1)]),_c('b-card-body',[_c('EKTable',{attrs:{"columns":_vm.columns,"items":_vm.invoiceDto.codes,"selectedLabel":"name","no_delete":"","no_select":_vm.mode,"row-style-class":_vm.isRemoved},on:{"selected-rows":_vm.getSelectedCodes},scopedSlots:_vm._u([{key:"items.date",fn:function(ref){
                                        var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleDateString("en-GB"))+" ")]}},{key:"items.posRate",fn:function(ref){
                                        var value = ref.value;
return [_vm._v(" "+_vm._s(value.toFixed(2))+" ")]}}])}),(!this.selectedCodes.length && !_vm.mode)?_c('h6',{staticClass:"text-danger text-center"},[_vm._v(" يجب تحديد كود واحد على الأقل ")]):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"ملاحظات","label-for":"note"}},[_c('b-form-textarea',{attrs:{"id":"note","rows":"6"},model:{value:(_vm.invoiceDto.notes),callback:function ($$v) {_vm.$set(_vm.invoiceDto, "notes", $$v)},expression:"invoiceDto.notes"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","lg":"12"}},[_c('ul',{staticClass:"list-unstyled p-0 m-0"},[_c('li',{staticClass:"d-flex pb-1 pt-2 justify-content-between align-items-center"},[_c('div',[_vm._v("عدد الأكواد")]),_c('div',[_vm._v(" "+_vm._s(!_vm.mode ? _vm.selectedCodes.length : _vm.invoiceDto.codeCount)+" ")])]),_c('li',{staticClass:"d-flex pt-1 justify-content-between align-items-center"},[_c('div',[_vm._v("الكلفة")]),_c('div',[_vm._v(_vm._s(!_vm.mode ? _vm.totalValue.toFixed(2) : _vm.invoiceDto.actualCost.toFixed(2))+" ل.س")])])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"d-none d-lg-block"})]),_c('b-col',{attrs:{"cols":"6","lg":"12"}},[_c('ul',{staticClass:"list-unstyled p-0"},[_c('li',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v("المستحق لنا")]),_c('div',[_vm._v(" "+_vm._s(!_vm.mode ? _vm.lastTotalValue.toFixed(2) : _vm.invoiceDto.dueCompany.toFixed(2))+" ل.س ")])])]),_c('ValidationObserver',{ref:"observerInvoice"},[_c('div',[(!_vm.mode)?_c('EKInputText',{attrs:{"label":"المبلغ المدفوع","labelCols":6,"name":"paidValue","type":"number","rules":[
                                                    {
                                                        type: 'required',
                                                        message:
                                                            'المبلغ المدفوع مطلوب'
                                                    },
                                                    {
                                                        type: 'min_value:0',
                                                        message:
                                                            'يجب ان تكون القيمة موجبة'
                                                    }
                                                ]},model:{value:(
                                                    _vm.invoiceDto.paidMoney
                                                ),callback:function ($$v) {_vm.$set(_vm.invoiceDto, "paidMoney", $$v)},expression:"\n                                                    invoiceDto.paidMoney\n                                                "}}):_c('EKInputText',{attrs:{"labelCols":6,"value":_vm.invoiceDto.paidMoney,"label":"المبلغ المدفوع","name":"paidValue","type":"number","readonly":""}})],1)])],1)],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('b-card',[(!_vm.mode)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.onSubmit(_vm.invoiceDto, 0)}}},[_vm._v("حفظ")]):_vm._e(),(!_vm.mode)?_c('b-button',{attrs:{"variant":"outline-primary","block":""},on:{"click":function($event){return _vm.onSubmit(_vm.invoiceDto, 1)}}},[_vm._v("حفظ و طباعة")]):_c('b-button',{attrs:{"variant":"outline-primary","block":""},on:{"click":function($event){return _vm.print()}}},[_vm._v("طباعة")]),(_vm.mode)?_c('b-button',{attrs:{"variant":"outline-danger","block":""},on:{"click":function($event){return _vm.deleteInvoice(_vm.invoiceDto.invoiceId)}}},[_vm._v("حذف")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-primary","to":'/invoice/' + _vm.id,"block":""}},[_vm._v("عودة")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }